<template>
  <div class="page_footer"></div>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {};
  },
};
</script>

<style scoped>
.page_footer {
  @apply w-full;
  height: 10vh;
}
</style>
