import { createWebHistory, createRouter } from "vue-router";

import MainPage from "@/pages/MainPage.vue";
import EventInfoPage from "@/pages/EventInfoPage.vue";
import TalentPage from "@/pages/TalentPage.vue";
import PlaceholderPage from "@/pages/PlaceholderPage.vue";
import ContributorsPage from "@/pages/ContributorsPage.vue";

const routes = [
  { path: "/", component: PlaceholderPage },
  { path: "/main_page", component: MainPage },
  { path: "/event_info", component: EventInfoPage },
  { path: "/talent_page", component: TalentPage },
  { path: "/contributors", component: ContributorsPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default {
  router,
};
