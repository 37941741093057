<template>
  <div class="contributor_card">
    <div class="contributor_image_container">
      <img
        class="contributor_image"
        :src="getImagePath(contributer)"
        :alt="contributer.name"
        srcset=""
      />
    </div>
    <h2 class="contributor_alias">{{ contributer.name }}</h2>
    <h4 class="contributor_title">{{ contributer.title }}</h4>
    <div class="contributor_link_container">
      <a
        v-for="link in contributer.links"
        :key="contributer.name + link.name"
        class="contributor_link"
        href="mailto:contact@vtubervalhalla.com"
        target="_blank"
        title="Contact us through E-mail"
      >
        <TwitterSvg
          v-if="link.name == 'twitter'"
          class="contributor_link_image"
        />

        <TwitchSvg
          v-else-if="link.name == 'twitch'"
          class="contributor_link_image"
        />

        <YoutubeSvg
          v-else-if="link.name == 'youtube'"
          class="contributor_link_image"
        />
      </a>
    </div>
  </div>
</template>

<script>
import TwitterSvg from "./icons/TwitterSvg.vue";
import TwitchSvg from "./icons/TwitchSvg.vue";
import YoutubeSvg from "./icons/YoutubeSvg.vue";

export default {
  components: { TwitterSvg, TwitchSvg, YoutubeSvg },
  props: {
    contributer: {
      type: Object,
      default: () => {
        return {
          id: "default",
          name: "Contributor",
          title: "Example Contrib",
          imgSrc: "Hao",

          links: {
            twitter: {
              name: "twitter",
              link: "",
              tooltip: "Twitter",
            },
            twitch: {
              name: "twitch",
              link: "",
              tooltip: "Twitch",
            },
            youtube: {
              name: "youtube",
              link: "",
              tooltip: "Twitch",
            },
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    getImagePath(link) {
      console.log("Card: ", link);
      let imageName = link.imgSrc;
      if (imageName.length > 0) {
        return require("../assets/" + imageName + ".png");
      } else {
        return require("../assets/Hao.png");
      }
    },
  },
  computed: {},
};
</script>

<style>
.contributor_card {
  @apply w-full flex flex-col justify-center;
  @apply border border-solid border-navbar-blue;
  @apply bg-navbar-blue;
  padding: 5% 0 0 0;
  border: 1px solid var(--navbar-blue);
  background-color: var(--header-blue);
}

.contributor_image_container {
  border-radius: 50%;
  overflow: hidden;
  width: 70%;
  aspect-ratio: 1/1;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}

.contributor_image {
  border-radius: 50%;
}

.contributor_alias {
  pointer-events: none;
  font-size: xx-large;
  text-align: center;
}

.contributor_title {
  pointer-events: none;
  font-size: x-large;
  text-align: center;
}

.contributor_link_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20%;
  padding: 5%;
}

.contributor_link {
  flex: 1;
  border-radius: 50%;
  border: 1px solid var(--header-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 1/1;
  background-color: var(--header-blue);
}

.contributor_link_image {
  width: 50%;
  height: auto;
  margin: 0 auto;
}
</style>
