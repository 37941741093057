<template>
  <img
    src="../../assets/Just helmet No background.svg"
    alt=""
    srcset=""
    class="logo_icon"
    @click="clicked_icon"
  />
</template>

<script>
export default {
  name: "LogoIcon",
  data() {
    return {};
  },
  methods: {
    clicked_icon: function () {
      this.$emit("onClicked");
    },
  },
};
</script>

<style scoped>
.logo_icon {
  @apply h-14 max-h-full;
}
</style>
