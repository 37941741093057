const TALENTS = {
  fuwamay: {
    name: "fuwamay",
    displayName: "Fuwamay",
    image: "fuwamay.png",
    showInfo: false,
    description:
      "An elf from another world! Fuwamay is a cozy and chaotic gremlin of an elf who streams a wide variety of games, sings and draws. She is particularly fond of JRPGs and is on a mission to play every Final Fantasy on stream.",
    socials: [
      { site: "youtube", link: "https://youtube.com/@fuwamay" },
      { site: "twitch", link: "https://twitch.tv/fuwamay" },
      { site: "twitter", link: "https://twitter.com/fuwamay" },
      {
        site: "bluesky",
        link: "https://bsky.app/profile/fuwamaych.bsky.social",
      },
      { site: "tiktok", link: "https://www.tiktok.com/@fuwamay" },
      { site: "instagram", link: "https://www.instagram.com/fuwamay/" },
    ],
  },
  keemo: {
    name: "keemo",
    displayName: "Keemo",
    image: "keemo.png",
    showInfo: true,
    description:
      "Hello! My name is Keemo, I'm a raccoon vtuber and I stream whatever game I have, I like Tekken, Wrestling and Yakuza.",
    socials: [
      { site: "twitch", link: "https://www.twitch.tv/keemo_p_lotor" },
      { site: "twitter", link: "https://x.com/Keemo_P_Lotor" },
      {
        site: "bluesky",
        link: "https://bsky.app/profile/keemoplotor.bsky.social",
      },
    ],
  },
  dandyfloss: {
    name: "dandyfloss",
    displayName: "Dandyfloss",
    image: "fuwamay.png",
    showInfo: false,
    description: "",
    socials: [{ site: "", link: "" }],
  },
  varidetta: {
    name: "varidetta",
    displayName: "Varidetta",
    image: "fuwamay.png",
    showInfo: false,
    description: "",
    socials: [{ site: "", link: "" }],
  },
  fuwawmay: {
    name: "fuwamay",
    displayName: "Fuwawmay",
    image: "fuwamay.png",
    showInfo: false,
    description:
      "An elf from another world! Fuwamay is a cozy and chaotic gremlin of an elf who streams a wide variety of games, sings and draws. She is particularly fond of JRPGs and is on a mission to play every Final Fantasy on stream.",
    socials: [
      { site: "youtube", link: "https://youtube.com/@fuwamay" },
      { site: "twitch", link: "https://twitch.tv/fuwamay" },
      { site: "twitter", link: "https://twitter.com/fuwamay" },
      {
        site: "bluesky",
        link: "https://bsky.app/profile/fuwamaych.bsky.social",
      },
      { site: "tiktok", link: "https://www.tiktok.com/@fuwamay" },
      { site: "instagram", link: "https://www.instagram.com/fuwamay/" },
    ],
  },
  kewemo: {
    name: "keemo",
    displayName: "Kweemo",
    image: "keemo.png",
    showInfo: true,
    description:
      "Hello! My name is Keemo, I'm a raccoon vtuber and I stream whatever game I have, I like Tekken, Wrestling and Yakuza.",
    socials: [
      { site: "twitch", link: "https://www.twitch.tv/keemo_p_lotor" },
      { site: "twitter", link: "https://x.com/Keemo_P_Lotor" },
      {
        site: "bluesky",
        link: "https://bsky.app/profile/keemoplotor.bsky.social",
      },
    ],
  },
  dandwyfloss: {
    name: "dandyfloss",
    displayName: "Dandwyfloss",
    image: "fuwamay.png",
    showInfo: false,
    description: "",
    socials: [{ site: "", link: "" }],
  },
  varidewtta: {
    name: "varidetta",
    displayName: "Varidwetta",
    image: "fuwamay.png",
    showInfo: false,
    description: "",
    socials: [{ site: "", link: "" }],
  },
};

function getTalents() {
  return TALENTS;
}

export { getTalents };
