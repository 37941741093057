<template>
  <div class="site_content">
    <HeaderNavBar v-if="!is_placeholder" />
    <main>
      <RouterView />
    </main>
    <PageFooter v-if="!is_placeholder" />
  </div>
</template>

<script>
import HeaderNavBar from "@/components/HeaderNavBar.vue";
import PageFooter from "@/components/PageFooter.vue";
export default {
  name: "RouterPage",
  components: { HeaderNavBar, PageFooter },
  computed: {
    is_placeholder: function () {
      return this.$route.fullPath == "/";
    },
  },
};
</script>

<style scoped>
.site_content {
  @apply h-full w-full;
}
</style>
