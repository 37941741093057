<template>
  <div class="contributors_page">
    <h1 class="contributors_header">Contributors</h1>
    <div class="contributors_container">
      <ContributerCard
        v-for="contributer in contributors()"
        :key="contributer.id"
        :contributer="contributer"
      />
    </div>
  </div>
</template>

<script>
import ContributerCard from "@/components/ContributerCard.vue";
// import { contributers } from "@/services/Contributors";
// import the contributers from the contributors.js file
import { getContributors } from "@/services/ContributorService";


export default {
  components: { ContributerCard },
  methods: {
    contributors() {
      return getContributors();
    },
  },
};
</script>

<style scoped>
.contributors_page {
  @apply flex flex-col items-center;
}

.contributors_header {
  @apply font-tourney text-center mx-0 text-5xl mt-12 mb-10;
}

.contributors_container {
  @apply w-5/6 mt-6 mx-auto grid grid-cols-1 sm:grid-cols-3 gap-y-10 gap-x-12;
}
</style>
