<template>
  <nav class="header-nav">
    <div class="header-nav-container">
      <RouterLink to="/" class="header-nav-logo-link">
        <LogoSvg />
        <span class="header-nav-logo-text">
          <img class="header-nav-logo-text-img" src="../assets/VV_Title.png" alt="VTuber Vallhalla">
        </span>
      </RouterLink>
      <button @click="toggleMenu" data-collapse-toggle="navbar-default" type="button" class="burger-button" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open menu</span>
        <svg class="burger-button-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
      <div id="navbar-default" :class="menuOpen ? '': 'hidden'" class="header-nav-menu-container">
        <ul class="header-nav-menu">
          <li>
            <RouterLink to="/main_page" @click="resetMenu" class="header-nav-menu-item" aria-current="page">Home</RouterLink>
          </li>
          <li>
            <RouterLink to="/event_info" @click="resetMenu" class="header-nav-menu-item">Event</RouterLink>
          </li>
          <li>
            <RouterLink to="/talent_page" @click="resetMenu" class="header-nav-menu-item">Talents</RouterLink>
          </li>
          <li>
            <RouterLink to="/contributors" @click="resetMenu" class="header-nav-menu-item">Contributors</RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import LogoSvg from "./icons/LogoSvg.vue";

export default {
  name: "HeaderNavBar",
  props: {},
  components: { LogoSvg },
  data() {
    return {
      menuOpen: false,
    }
  },
  methods: {
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
    },
    resetMenu: function () {
      this.menuOpen = false;
    }
  },
};
</script>

<style scoped>
.header-nav {
  @apply sticky top-0;
  @apply bg-navbar-blue text-black;
}

.header-nav-container {
  @apply max-w-screen-xl flex flex-wrap items-center justify-between;
  @apply mx-auto px-4 py-2;
}

.header-nav-logo-link {
  @apply flex items-center space-x-3 rtl:space-x-reverse;
}

.header-nav-logo-text {
  @apply self-center text-2xl font-semibold whitespace-nowrap;
}

.header-nav-logo-text-img {
  @apply h-10;
}

/* burger button */
.burger-button {
  @apply p-2 w-10 h-10 text-sm text-blue-200 rounded-lg;
  @apply inline-flex items-center justify-center;
  @apply md:hidden hover:bg-navbar-menu-blue;
  @apply focus:outline-none focus:ring-1 focus:ring-blue-200;
}

.burger-button-icon {
  @apply w-5 h-5;
}

/* right side of the navbar and menu */
.header-nav-menu-container {
  @apply w-full;
  @apply md:block md:w-auto;
}

.header-nav-menu {
  @apply font-medium flex flex-col p-4;
  @apply bg-transparent border rounded border-navbar-menu-blue;
  @apply md:p-0 mt-4 md:mt-0 md:border-0 md:flex-row md:space-x-8 rtl:space-x-reverse;
}

.header-nav-menu-item {
  @apply block py-2 px-3 text-white rounded-sm;
  @apply hover:bg-navbar-menu-blue;
  @apply md:p-0 md:border-0 md:hover:bg-transparent md:hover:text-blue-200;
}

/* Active menu item */
.header-nav-menu-item.router-link-exact-active {
  @apply bg-navbar-menu-blue text-blue-200;
  @apply md:bg-transparent;
}
</style>
