<template>
  <div id="site">
    <RouterContainer v-if="get_prod_mode" />
    <PlaceholderPage v-if="!get_prod_mode" />
  </div>
</template>

<script>
import RouterContainer from "./pages/RouterContainer.vue";
import PlaceholderPage from "./pages/PlaceholderPage.vue";

export default {
  name: "App",
  components: { RouterContainer, PlaceholderPage },
  mounted() {},
  data() {
    return { prod: true };
  },
  methods: {},
  computed: {
    get_prod_mode: function () {
      return this.prod;
    },
  },
};
</script>

<style>
#site {
  @apply h-full w-full;
}
</style>
