<template>
  <div class="event_info_page">
    <div class="event_info_text">
      <h2 class="event_info_header">Event Information</h2>
      <p class="event_info_breadtext">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        gravida eu metus quis mollis. Mauris semper est tristique, aliquam massa
        ut, gravida felis. Mauris quis risus ultricies nisl fermentum laoreet.
        Aliquam quis lacinia nunc, quis posuere orci. Curabitur sit amet purus
        at leo tristique rhoncus. Praesent consectetur justo at ex tristique,
        sit amet pellentesque eros malesuada. Quisque et enim vulputate purus
        porttitor pulvinar eu efficitur purus. Aliquam ligula massa, pulvinar id
        est faucibus, euismod consectetur est. Mauris sodales erat sed lectus
        cursus porta. Ut posuere vehicula tincidunt. Phasellus euismod elit vel
        nunc feugiat, a commodo erat aliquet.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "eventInfoPage",
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.event_info_page {
  @apply flex flex-col;
}

.event_info_text {
  @apply text-main-fontcolor bg-header-blue;
  @apply flex flex-col items-center justify-center text-center;
  @apply h-fit w-full;

  padding: 3% 0;
}

.event_info_header {
  @apply m-0 mb-5;
}

.event_info_breadtext {
  @apply w-1/2 text-secondary-fontcolor;
}
</style>
