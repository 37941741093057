const CONTRIBUTORS = {
  hao: {
    id: "hao",
    name: "Hao",
    title: "Goat",
    imgSrc: "Hao",

    links: {
      twitter: {
        name: "twitter",
        imgSrc: "twitter",
        link: "",
        tooltip: "Twitter",
      },
      twitch: {
        name: "twitch",
        link: "",
        tooltip: "Twitch",
      },
      youtube: {
        name: "youtube",
        link: "",
        tooltip: "Twitch",
      },
    },
  },
  alex: {
    id: "alex",
    name: "Alex",
    title: "UX-Designer",
    imgSrc: "Alex",

    links: {
      twitter: {
        name: "twitter",
        imgSrc: "twitter",
        link: "",
        tooltip: "Twitter",
      },
      twitch: {
        name: "twitch",
        link: "",
        tooltip: "Twitch",
      },
      youtube: {
        name: "youtube",
        link: "",
        tooltip: "Twitch",
      },
    },
  },
};

function getContributors() {
  return CONTRIBUTORS;
}

export { getContributors };
