<template>
  <div class="talent_page">
    <h1 class="guest_header">Guests</h1>
    <div class="guest_list">
      <TalentCard
        v-for="(talent, idx) in talents()"
        :key="talent.name + idx"
        :talent="talent"
      />
    </div>
  </div>
</template>

<script>
import TalentCard from "@/components/TalentCard.vue";
import { getTalents } from "@/services/TalentService";

export default {
  components: { TalentCard },
  data() {
    return { };
  },
  methods: {
    talents() {
      return getTalents();
    },
  },
};
</script>

<style scoped>
.talent_page {
  @apply flex flex-col w-full;
}

.guest_header {
  @apply font-tourney text-center mx-0 text-5xl mt-12 mb-10;
}

.guest_list {
  @apply w-full mt-6 grid grid-cols-1 sm:grid-cols-3 gap-y-10;
}
</style>
