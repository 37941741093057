<template>
  <div class="talent_card">
    <div class="talent_container">
      <img
        class="talent_image"
        :src="loadImage(talent.name, talent.image)"
        :alt="talent.displayName"
      />
      <h2 class="talent_name">{{ talent.displayName }}</h2>
      <p class="talent_description" v-if="talent.showInfo">
        {{ talent.description }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
    props: {
      talent: {
        type: Object,
        default: () => {
          return {
            name: "DefaultTalent",
            displayName: "Default Talent",
            image: "poster_door_2.png",
            showInfo: false,
            description: "This is a default talent",
            socials:[
              { site: "twitter", link: "", tooltip: "Twitter" },
              { site: "twitch", link: "", tooltip: "Twitch" },
              { site: "youtube", link: "", tooltip: "Youtube" },
            ],
          };
      },
    },
  },
  methods: {
    loadImage(talentId, imageName) {
      try {
        return require("../assets/" + talentId + imageName);
      } catch (error) {
        console.error("Error loading image: ", error);
        return require("../assets/poster_door_2.png");
      }
    },
  },
};
</script>

<style scoped>
.talent_container {
  @apply w-full flex flex-col items-center justify-center;
}

.talent_image {
  @apply w-4/5;
}
</style>