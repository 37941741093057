<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 439 512.17"
  >
    <g fill-rule="nonzero">
      <path
        fill="#FEFEFE"
        d="M402.42 237.79l-73.17 73.17h-73.17l-64.02 64.02v-64.02h-82.31V36.59h292.67z"
      />
      <path
        fill="#9F77F7"
        d="M402.42 237.79l-73.17 73.17h-73.17l-64.02 64.02v-64.02h-82.31V36.59h292.67v201.2zM91.46 0L0 91.46v329.25h109.75v91.46l91.46-91.46h73.17L439 256.08V0H91.46z"
      />
      <path
        fill="#9F77F7"
        d="M310.96 210.35h36.58V100.6h-36.58zM210.35 210.35h36.59V100.6h-36.59z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
