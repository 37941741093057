<template>
  <div class="main_page">
    <div class="main_content">
      <div class="hero_img_container">
        <img class="hero_image" src="..\assets\Hero_image.svg" alt="">
      </div>
      <div class="w-full flex flex-col items-center">
        <button @click="goToTalents" type="button" class="w-2/3 md:w-1/4 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
          More Info
        </button>
      </div>
      <!--<MoreInfoSvg class="more_info" />-->
    </div>
  </div>
</template>

<script>
//import MoreInfoSvg from "@/components/icons/MoreInfoSvg.vue";

export default {
  name: "MainPage",
  //components: { MoreInfoSvg },
  props: {},
  methods: {
    goToTalents: function () {
      this.$router.push("/talent_page");
    },
  },
};
</script>

<style scoped>
.main_page {
  @apply h-full w-full flex flex-col items-center;
}

.main_content {
  @apply flex flex-col h-full w-full;
}

.hero_image {
  @apply w-full;
  @apply flex justify-center items-center self-center;
}

.hero_image {
  @apply w-full mb-10;
  /*background-image: url("..\\assets\\Hero_image.svg");
  @apply bg-no-repeat bg-contain bg-top bg-scroll;*/
}

.more_info {}
</style>
