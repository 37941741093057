<template>
  <div class="placeholder-page">
    <div class="info-container">
      <h1>
        We are currently setting things up <br />
        Please wait for further announcement
        <span class="s" @click="go_to_demo">s</span>
      </h1>
      <span class="media-links">
        <a href="mailto:contact@vtubervalhalla.com" target="_blank" title="Contact us through E-mail">
          <svg class="media-link-img" fill="#FFFFFF" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
            <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/>
          </svg>
        </a>
        <a href="https://youtube.com/@vtubervalhalla?si=0u36Y-jxRg4DxvNp" target="_blank" title="Follow us on Youtube">
          <img class="media-link-img" src="..\\assets\\youtube_white.png" alt="Youtube Link" />
        </a>
        <a href="https://bsky.app/profile/vtubervalhalla.bsky.social" target="_blank" title="Follow us on Bluesky">
          <img class="media-link-img" src="..\\assets\\bluesky_white.png" alt="Follow us on Bluesky" />
        </a>
        <a href="https://x.com/VtuberValhalla" target="_blank" title="Follow us on X (Fomerly known as Twitter)">
          <img class="media-link-img" src="..\\assets\\twitter_white.png" alt="Follow us on X (Fomerly known as Twitter)" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlaceholderPage",
  components: {},
  data() {
    return {};
  },
  methods: {
    go_to_demo: function () {
      this.$router.push("/main_page");
    },
  },
  computed: {},
};
</script>

<style scoped>
.placeholder-page {
  @apply flex h-screen w-full;
  @apply text-yellow-300 text-xl xl:text-2xl;
}
.placeholder-page:before {
  content: "";
  @apply absolute inset-0 bg-black opacity-90 -z-10;
  @apply bg-no-repeat bg-60 sm:bg-30 bg-bottom sm:bg-right-bottom bg-blend-lighten;
  background-image: url("..\\assets\\vava_sip_smol.png");
}

.info-container {
  @apply flex flex-col justify-center;
  @apply text-center w-full;
}

.media-links {
  @apply flex flex-row justify-center space-x-4;
  @apply w-full md:w-1/2 h-12 md:h-28 my-4 mx-auto;
}

.media-link-img {
  @apply h-full max-h-14;
}

.s {
  @apply absolute m-0;
}
</style>
